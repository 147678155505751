























import Component from "vue-class-component";
import Vue from "vue";
import {Emit, ModelSync, Prop} from "vue-property-decorator";

@Component
export default class PasswordForm extends Vue {
    @Prop({type: String})
    title!: string;


    @ModelSync('show', 'update:show', {type: Boolean})
    shouldShow!: boolean;

    @ModelSync('modelValue', 'update:modelValue', {type: String})
    password!: string;

    @Emit()
    submit(pw: string) {
        console.log('item submitted', pw);
    }
}
