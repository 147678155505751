var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.refresh}},[_vm._v(" Refresh "),_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary"},on:{"click":function($event){_vm.showCreate=true}}},'v-btn',attrs,false),on),[_vm._v(" Create "),_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}]),model:{value:(_vm.showCreate),callback:function ($$v) {_vm.showCreate=$$v},expression:"showCreate"}},[_c('user-form',{attrs:{"show":_vm.showCreate,"title":"Create User"},on:{"update:show":function($event){_vm.showCreate=$event},"submit":function($event){return _vm.register(_vm.prototype)}},model:{value:(_vm.prototype),callback:function ($$v) {_vm.prototype=$$v},expression:"prototype"}})],1),_c('v-dialog',{model:{value:(_vm.showPassword),callback:function ($$v) {_vm.showPassword=$$v},expression:"showPassword"}},[_c('password',{attrs:{"show":_vm.showPassword,"title":_vm.passwordTitle},on:{"update:show":function($event){_vm.showPassword=$event},"submit":function($event){return _vm.updatePassword(_vm.selectedUser, _vm.currPassword)}},model:{value:(_vm.currPassword),callback:function ($$v) {_vm.currPassword=$$v},expression:"currPassword"}})],1),_c('v-dialog',{model:{value:(_vm.showIp),callback:function ($$v) {_vm.showIp=$$v},expression:"showIp"}},[_c('i-p-form',{attrs:{"title":"IP addresses"},model:{value:(_vm.selectedUserIpWhitelist),callback:function ($$v) {_vm.selectedUserIpWhitelist=$$v},expression:"selectedUserIpWhitelist"}})],1)],1),_c('v-alert',{attrs:{"close-label":"Close","dismissible":"","type":"error"},model:{value:(_vm.showError),callback:function ($$v) {_vm.showError=$$v},expression:"showError"}},[_vm._v(" Login failed: "+_vm._s(_vm.errorMessage)+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.loading},scopedSlots:_vm._u([{key:"header.email",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',[_vm._v("mdi-at")])]}},{key:"header.company",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',[_vm._v("mdi-domain")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('edit-dialog',{on:{"update":function($event){return _vm.updateItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('edit-dialog',{on:{"update":function($event){return _vm.updateItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.attempts",fn:function(ref){
var item = ref.item;
return [_c('edit-dialog',{on:{"update":function($event){return _vm.updateItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","hide-details":"","type":"number"},model:{value:(item.attempts),callback:function ($$v) {_vm.$set(item, "attempts", $$v)},expression:"item.attempts"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.attempts)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-spacer'),_c('v-tooltip-btn',{attrs:{"btn-icon":"mdi-form-textbox-password","hint":"Change Password","icon-color":"primary"},on:{"click":function($event){_vm.showPassword = true; _vm.selectedUser = item}}}),_c('v-tooltip-btn',{attrs:{"btn-icon":"mdi-ip","hint":"Manage allowed IP addresses","color":"secondary","icon":""},on:{"click":function($event){_vm.selectedUser=item; _vm.showIp=true}}}),_c('v-tooltip-btn',{attrs:{"btn-icon":"mdi-delete","hint":"Delete","icon-color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }