








































































































import Component from "vue-class-component";
import Vue from "vue";
import EditDialog from "@/components/EditDialog.vue";
import MyPbxToolsApi from "@/common/MyPbxToolsApi";
import UserData from "@/common/data/UserData";
import BaseUserData from "@/common/data/BaseUserData";
import UserForm from "@/components/UserForm.vue";
import Password from "@/components/Password.vue";
import IPForm from "@/components/IPForm.vue";
import {Watch} from "vue-property-decorator";

@Component({
    components: {IPForm: IPForm, Password, UserForm, EditDialog}
})
export default class UserManager extends Vue {
    get api(): MyPbxToolsApi {
        return MyPbxToolsApi.instance;
    }

    showCreate: boolean = false;
    showIp: boolean = false;

    users: UserData[] = [];
    loading: boolean = false;
    showError: boolean = false;
    errorMessage: string = "";
    currPassword: string = "";
    showPassword: boolean = false;
    selectedUser: UserData | null = null;
    headers: any[] = [
        {text: "ID", value: "id", align: 'right'},
        {text: "Name", value: "name"},
        {text: "Email", value: "email"},
        {text: "Company", value: "company"},
        {text: "Number of Attempts", value: "attempts", align: 'right'},
        {text: '', value: 'actions', align: 'right'},
    ];
    get selectedUserIpWhitelist(): string[] {
        return this.selectedUser?.ipWhitelist ?? [];
    }

    set selectedUserIpWhitelist(v: string[]) {
        if (this.selectedUser)
            this.selectedUser.ipWhitelist = v;
    }
    @Watch('selectedUserIpWhitelist')
    ipWhitelistUpdated(newVal: string[]) {
        console.log('update IP', newVal);
        const chosen = this.selectedUser;
        if (chosen === null)
            throw `No selected user!`;

        this.updateItem({
            id: chosen.id,
            ipWhitelist: newVal
        })
    }


    get allCompanies(): string[] {
        return this.users.map(u => u.company);
    }

    prototype: BaseUserData = {
        company: "",
        name: "",
        email: "",
        ipWhitelist: []
    };

    get passwordTitle(): string {
        return `Password for ${this.selectedUser?.name ?? ''}`;
    }

    mounted() {
        this.refresh();
    }

    async updatePasswordAsync(user: UserData, password: string) {
        return await MyPbxToolsApi.instance.doUserAction('reset_pw', {
            id: user.id,
            password: password
        });
    }

    updatePassword(user: UserData, password: string) {
        this.process(this.updatePasswordAsync(user, password));
    }

    process<T>(prom: Promise<T>): void {
        prom.catch(err => {
            this.errorMessage = err + '';
            this.showError = true;
            console.error('processed error: ' + err);
        });
    }

    refresh() {
        this.process(this.refreshAsync());
    }

    async refreshAsync() {
        this.loading = true;

        this.users = await MyPbxToolsApi.instance.listUsers();
        this.loading = false;
    }

    updateItem(item: Partial<UserData>) {

        this.process(this.updateItemAsync(item).catch(err => console.error(err)));
    }

    async deleteItemAsync(item: UserData): Promise<void> {
        await MyPbxToolsApi.instance.doUserAction('delete', {id: item.id});
        this.users = this.users.filter(u => u.id !== item.id);
    }

    deleteItem(item: UserData) {
        this.process(this.deleteItemAsync(item));
    }

    async updateItemAsync(item: Partial<UserData>) {
        await MyPbxToolsApi.instance.doUserAction('update', item);
    }

    async registerAsync(item: BaseUserData) {
        const user: UserData = await MyPbxToolsApi.instance.doUserAction('create', item);
        this.users.push(user);
        this.showCreate = false;
    }


    register() {
        this.process(this.registerAsync(this.prototype));
    }
}
