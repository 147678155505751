

































import Component from "vue-class-component";
import Vue from "vue";
import {Emit, ModelSync, Prop, PropSync} from "vue-property-decorator";
import BaseUserData from "@/common/data/BaseUserData";
import UserData from "@/common/data/UserData";
import MyPbxToolsApi from "@/common/MyPbxToolsApi";

@Component
export default class UserForm extends Vue {
    @Prop({type: String})
    title!: string;


    @PropSync('show', {type: Boolean})
    shouldShow!: boolean;

    @ModelSync('modelValue', 'update:modelValue', {type: Object})
    prototype!: BaseUserData;

    get googleLinkHref(): string {
        return MyPbxToolsApi.BASE_URL + '/api/general/v1.0/google/register.php?jwt=' + encodeURIComponent(MyPbxToolsApi.instance.jwtToken ?? "")
    }

    get userData(): UserData | null {
        return (this.prototype as any).id ? this.prototype as UserData : null;
    }

    @Emit()
    submit(prototype: BaseUserData) {
        this.close()
        console.log('item submitted', prototype);
    }

    @Emit()
    close() {

    }

    async unlinkAsync() {
        const userData = this.userData!;
        await MyPbxToolsApi.instance.doUserAction('unlink_google', {id: userData.id ?? -1});
        userData.googleSub = "";
    }

    unlink() {
        this.unlinkAsync().catch(console.error);
    }
}
