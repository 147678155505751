



































import Component from "vue-class-component";
import Vue from "vue";
import {ModelSync, Prop} from "vue-property-decorator";
import EditDialog from "@/components/EditDialog.vue";


interface IpItem {
    ip: string;
}

@Component({
    components: {EditDialog}
})
export default class IPForm extends Vue {
    @Prop({type: String})
    title!: string;

    @ModelSync('modelValue', 'update:modelValue', {type: Array})
    rawItems!: Array<string>;

    prototype: IpItem = {ip: "0.0.0.0"};
    showCreate: boolean = false;

    headers: any[] = [
        {text: 'IP Address', value: 'ip'},
        {text: '', value: 'actions', align: 'right'}
    ];

    get items(): Array<IpItem> {
        return this.rawItems.map(v => ({ip: v}));
    }

    set items(items: Array<IpItem>) {
        this.$emit('update:modelValue', items.map(i => i.ip));
    }

    addItem(item: IpItem) {
        this.items = [...this.items, item];
    }
    deleteItem(item: IpItem) {
        this.items = this.items.filter(i => i.ip !== item.ip);
    }

}
